'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import { useTranslation } from 'react-i18next'
import { useDebounceFn } from 'ahooks'
import { useRouter, useSearchParams } from 'next/navigation'

import AppCard from './AppCard'
import NewAppCard from './NewAppCard'
import useAppsQueryState from './hooks/useAppsQueryState'
import type { AppListResponse } from '@/models/app'
import { fetchAppList } from '@/service/apps'
import { useAppContext } from '@/context/app-context'
import { NEED_REFRESH_APP_LIST_KEY } from '@/config'
import { CheckModal } from '@/hooks/use-pay'
import { useTabSearchParams } from '@/hooks/use-tab-searchparams'
// import TabSlider from '@/app/components/base/tab-slider'
import { SearchLg } from '@/app/components/base/icons/src/vender/line/general'
import { XCircle } from '@/app/components/base/icons/src/vender/solid/general'
import { useProviderContext } from '@/context/provider-context'
import TooltipPlus from '@/app/components/base/tooltip-plus'
import i18n from '@/i18n/i18next-config'
import BindShopifyShopDialog from '@/app/components/app/shopify/BindShopifyShop'
import { updateShopifyBindStatus } from '@/service/integrations'
import { DotsGrid } from '@/app/components/base/icons/src/vender/line/general'
import {
  ChatBot,
  CuteRobot,
} from '@/app/components/base/icons/src/vender/line/communication'
import { Route } from '@/app/components/base/icons/src/vender/line/mapsAndTravel'
import { useStore as useTagStore } from '@/app/components/base/tag-management/store'
import TagManagementModal from '@/app/components/base/tag-management'

const getKey = (
  pageIndex: number,
  previousPageData: AppListResponse,
  activeTab: string,
  keywords: string,
) => {
  if (!pageIndex || previousPageData.has_more) {
    const params: any = { url: 'apps', params: { page: pageIndex + 1, limit: 30, name: keywords } }

    if (activeTab !== 'all')
      params.params.mode = activeTab

    return params
  }
  return null
}

const Apps = () => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  const router = useRouter()

  const { isCurrentWorkspaceEditor, isCurrentWorkspaceDatasetOperator } = useAppContext()
  const showTagManagementModal = useTagStore(s => s.showTagManagementModal)

  const [activeTab, setActiveTab] = useTabSearchParams({
    defaultTab: 'all',
  })
  const { query: { tagIDs = [], keywords = '' }, setQuery } = useAppsQueryState()
  const [tagFilterValue, setTagFilterValue] = useState<string[]>(tagIDs)
  const [searchKeywords, setSearchKeywords] = useState(keywords)
  const setKeywords = useCallback((keywords: string) => {
    setQuery(prev => ({ ...prev, keywords }))
  }, [setQuery])
  const setTagIDs = useCallback((tagIDs: string[]) => {
    setQuery(prev => ({ ...prev, tagIDs }))
  }, [setQuery])

  const { data, isLoading, setSize, mutate } = useSWRInfinite(
    (pageIndex: number, previousPageData: AppListResponse) => getKey(pageIndex, previousPageData, activeTab, searchKeywords),
    fetchAppList,
    { revalidateFirstPage: false },
  )

  const anchorRef = useRef<HTMLDivElement>(null)
  const createAppCardRef = useRef<HTMLAnchorElement>(null)

  const options = [
    { value: 'all', text: systemT('app.types.all'), icon: <DotsGrid className='w-[14px] h-[14px] mr-1' /> },
    { value: 'chat', text: systemT('app.types.chatbot'), icon: <ChatBot className='w-[14px] h-[14px] mr-1' /> },
    { value: 'agent-chat', text: systemT('app.types.agent'), icon: <CuteRobot className='w-[14px] h-[14px] mr-1' /> },
    { value: 'workflow', text: systemT('app.types.workflow'), icon: <Route className='w-[14px] h-[14px] mr-1' /> },
  ]

  useEffect(() => {
    document.title = `${systemT('common.menus.apps')} -  小象快答`
    if (localStorage.getItem(NEED_REFRESH_APP_LIST_KEY) === '1') {
      localStorage.removeItem(NEED_REFRESH_APP_LIST_KEY)
      mutate()
    }
  }, [mutate, systemT])

  useEffect(() => {
    if (isCurrentWorkspaceDatasetOperator)
      return router.replace('/datasets')
  }, [isCurrentWorkspaceDatasetOperator])

  const hasMore = data?.at(-1)?.has_more ?? true
  useEffect(() => {
    let observer: IntersectionObserver | undefined
    if (anchorRef.current) {
      observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLoading && hasMore)
          setSize((size: number) => size + 1)
      }, { rootMargin: '100px' })
      observer.observe(anchorRef.current)
    }
    return () => observer?.disconnect()
  }, [isLoading, setSize, anchorRef, mutate, hasMore])

  const { run: handleSearch } = useDebounceFn(() => {
    setSearchKeywords(keywords)
  }, { wait: 500 })

  const handleKeywordsChange = (value: string) => {
    setKeywords(value)
    handleSearch()
  }

  const handleClear = () => {
    handleKeywordsChange('')
  }

  const { workspaceLimitData, mutateWorkspaceLimitData } = useProviderContext()
  const [storeName, setStoreName] = useState('')

  const handleRefreshApp = async () => {
    await mutate()
    await mutateWorkspaceLimitData()
  }

  const [showBindShopDialog, setShowBindShopDialog] = useState(false)
  const searchParams = useSearchParams()

  useEffect(() => {
    if (searchParams.get('shopify_bind_id')) {
      // 判断当前绑定没有
      updateShopifyBindStatus({
        bind_id: searchParams.get('shopify_bind_id'),
      }).then((res) => {
        if (res.bind_status !== 'bind_success') {
          setShowBindShopDialog(true)
          setStoreName(res.name)
        }
        else {
          router.replace(`/app/${res.app_id}/configuration`)
        }
      })
    }
  }, [searchParams])

  const handleCreate = () => {
    createAppCardRef.current?.click()
  }

  return (
    <>
      {workspaceLimitData?.max_message_count <= workspaceLimitData?.message_count && (
        <div className='bg-uni-fill-14 rounded font-normal text-sm text-uni-text-8 mx-[48px] my-[16px] flex items-center px-[16px] py-[12px]'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM12 8.25C12.1989 8.25 12.3897 8.32902 12.5303 8.46967C12.671 8.61032 12.75 8.80109 12.75 9V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75V9C11.25 8.80109 11.329 8.61032 11.4697 8.46967C11.6103 8.32902 11.8011 8.25 12 8.25ZM12 16.5C12.1989 16.5 12.3897 16.421 12.5303 16.2803C12.671 16.1397 12.75 15.9489 12.75 15.75C12.75 15.5511 12.671 15.3603 12.5303 15.2197C12.3897 15.079 12.1989 15 12 15C11.8011 15 11.6103 15.079 11.4697 15.2197C11.329 15.3603 11.25 15.5511 11.25 15.75C11.25 15.9489 11.329 16.1397 11.4697 16.2803C11.6103 16.421 11.8011 16.5 12 16.5Z" fill="#FBA92F"/>
          </svg>
          <span className='ml-[8px]'>
            {systemT('app.workspaceLimit.message')}
          </span>
        </div>
      )}
      <div className='sticky top-0 flex justify-between items-center pt-4 px-12 pb-2 leading-[56px] bg-gray-100 z-10 flex-wrap gap-y-2'>
        <div className="flex items-center px-2 w-[200px] h-8 rounded-lg bg-gray-200">
          <div className="pointer-events-none shrink-0 flex items-center mr-1.5 justify-center w-4 h-4">
            <SearchLg className="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="query"
            className="grow block h-[18px] bg-gray-200 rounded-md border-0 text-gray-600 text-[13px] placeholder:text-gray-500 appearance-none outline-none"
            placeholder={systemT('common.operation.search')!}
            value={keywords}
            onChange={(e) => {
              handleKeywordsChange(e.target.value)
            }}
            autoComplete="off"
          />
          {
            keywords && (
              <div
                className='shrink-0 flex items-center justify-center w-4 h-4 cursor-pointer'
                onClick={handleClear}
              >
                <XCircle className='w-3.5 h-3.5 text-gray-400' />
              </div>
            )
          }
        </div>
        {/* <TabSlider
          value={activeTab}
          onChange={setActiveTab}
          options={options}
        /> */}

      </div>
      <nav className='grid content-start grid-cols-1 gap-4 px-12 pt-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grow shrink-0'>
        {workspaceLimitData?.max_application_count <= workspaceLimitData?.application_count
          ? (
            <TooltipPlus
              popupContent={systemT('workspace.confirm.application.title')}
            >
              {isCurrentWorkspaceEditor
          && <NewAppCard onSuccess={handleRefreshApp} disabled ref={createAppCardRef} />}
            </TooltipPlus>
          )
          : (
            isCurrentWorkspaceEditor && <NewAppCard onSuccess={handleRefreshApp} ref={createAppCardRef} />
          )}
        {data?.map(({ data: apps }: any) => apps.filter((app: any) => !app.name.includes('#hide')).map((app: any) => (
          <AppCard key={app.id} app={app} onRefresh={handleRefreshApp} />
        )))}
        <CheckModal />
      </nav>
      <div ref={anchorRef} className='h-0'> </div>
      {showTagManagementModal && (
        <TagManagementModal type='app' show={showTagManagementModal} />
      )}
      {showBindShopDialog && (
        <BindShopifyShopDialog
          show={showBindShopDialog}
          onCreateCallback={handleCreate}
          onClose={() => { setShowBindShopDialog(false) }}
          storeName={storeName}
          canCreateNewApp={workspaceLimitData?.max_application_count > workspaceLimitData?.application_count}></BindShopifyShopDialog>)}
    </>
  )
}

export default Apps
