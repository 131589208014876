'use client'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import s from './style.module.css'
import Modal from '@/app/components/base/modal'
import Button from '@/app/components/base/button'
import { BotType } from '@/types/app'
import type { AppDetailResponse } from '@/models/app'
import type { AppIconType, AppMode, EmbeddedChatbotThemeConfig, Language } from '@/types/app'
import { useToastContext } from '@/app/components/base/toast'
import { SimpleSelect } from '@/app/components/base/select'
import { useProviderContext } from '@/context/provider-context'
import i18n from '@/i18n/i18next-config'
import { languages } from '@/i18n/language'
import type { AppIconSelection } from '@/app/components/base/app-icon-picker'

export type ISettingsModalProps = {
  isChat: boolean
  appInfo: AppDetailResponse
  isShow: boolean
  defaultValue?: string
  isSetting?: boolean
  onClose: () => void
  onSave?: (params: ConfigParams) => Promise<void>
  onCreate?: (params: CreateAppParams) => Promise<void>
}

export type ConfigParams = {
  title: string
  description: string
  default_language: string
  chat_color_theme: string
  chat_color_theme_inverted: boolean
  prompt_public: boolean
  copyright: string
  privacy_policy: string
  custom_disclaimer: string
  icon_type: AppIconType
  icon: string
  white_list_websites: string[]
  icon_background?: string
  show_workflow_steps: boolean
  unixyz_background: string
  unixyz_icon: string
  embedded_chatbot_theme_config: EmbeddedChatbotThemeConfig
}

export type CreateAppParams = {
  name: string
  bot_type: BotType
  icon: string
  icon_background: string
  mode: AppMode // 客户前端都是聊天助手编排模式 advanced-chat
  config?: any
  default_language: Language
  white_list_websites: string[]
}

const prefixSettings = 'appOverview.overview.appInfo.settings'

const BOT_TYPE_OPTIONS = [
  { name: 'Chatbot', value: BotType.ChatBot },
  { name: 'Mailbot', value: BotType.MailBot },
]

const SettingsModal: FC<ISettingsModalProps> = ({
  appInfo,
  isShow = false,
  isSetting = true, // 默认设置
  onClose,
  onSave,
  onCreate,
}) => {
  const { notify } = useToastContext()
  const { workspaceLimitData } = useProviderContext()
  // const [isShowMore, setIsShowMore] = useState(false)
  const { name, bot_type: botType } = appInfo ?? {}
  const {
    title,
    icon_type,
    icon,
    icon_background,
    icon_url,
    description,
    chat_color_theme,
    chat_color_theme_inverted,
    copyright,
    privacy_policy,
    custom_disclaimer,
    default_language = languages[0].value,
    show_workflow_steps,
    white_list_websites,
  } = appInfo?.site ?? {}
  const [inputInfo, setInputInfo] = useState({
    title: name,
    desc: description,
    copyright,
    privacyPolicy: privacy_policy,
    botType,
  })
  const [language, setLanguage] = useState(default_language)

  const [saveLoading, setSaveLoading] = useState(false)
  const { t } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const [showAppIconPicker, setShowAppIconPicker] = useState(false)
  const [appIcon, setAppIcon] = useState<AppIconSelection>(
    icon_type === 'image'
      ? { type: 'image', url: icon_url!, fileId: icon }
      : { type: 'emoji', icon, background: icon_background! },
  )

  // 允许使用的网站
  const [websiteWhiteList, setWebsiteWhiteList] = useState<string[]>(white_list_websites ?? [''])
  const [isValidFail, setIsValidFail] = useState(false)

  // const onHide = () => {
  //   onClose()
  //   setTimeout(() => {
  //     setIsShowMore(false)
  //   }, 200)
  // }

  const onClickSave = async () => {
    if (!inputInfo.title) {
      notify({ type: 'error', message: t('app.newApp.nameNotEmpty') })
      return
    }
    if (isValidFail)
      return
    setSaveLoading(true)
    if (!isSetting) {
      // 新建
      const params = {
        name: inputInfo.title,
        default_language: language as string,
        icon_type: appIcon.type,
        icon: appIcon.type === 'emoji' ? appIcon.icon : appIcon.fileId,
        icon_background: appIcon.type === 'emoji' ? appIcon.background : undefined,
        mode: 'advanced-chat' as AppMode,
        white_list_websites: websiteWhiteList.filter(item => item),
        bot_type: inputInfo.botType,
      }
      await onCreate?.(params)
    }
    else {
      const params = {
        title: inputInfo.title,
        description: inputInfo.desc,
        default_language: language,
        prompt_public: false,
        copyright: inputInfo.copyright,
        privacy_policy: inputInfo.privacyPolicy,
        icon_type: appIcon.type,
        icon: appIcon.type === 'emoji' ? appIcon.icon : appIcon.fileId,
        icon_background: appIcon.type === 'emoji' ? appIcon.background : undefined,
        white_list_websites: websiteWhiteList.filter(item => item),
      }
      await onSave?.(params as ConfigParams)
    }
    setSaveLoading(false)
    onClose()
  }

  const onChange = (field: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value: string | boolean
      if (e.target.type === 'checkbox')
        value = (e.target as HTMLInputElement).checked
      else
        value = e.target.value

      setInputInfo(item => ({ ...item, [field]: value }))
    }
  }

  return (
    <>
      <Modal
        title={isSetting ? t(`${prefixSettings}.title`) : t('app.newApp.startToCreate')}
        isShow={isShow}
        onClose={onClose}
        className={`${s.settingsModal}`}
      >
        <div className={`mt-6 font-medium ${s.settingTitle} text-gray-900`}>{t(`${prefixSettings}.webName`)}</div>
        <div className='flex mt-2'>
          <input className={`flex-grow rounded-lg h-10 box-border px-3 ${s.projectName} bg-gray-100 appearance-none border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600`}
            value={inputInfo.title}
            onChange={onChange('title')}
            placeholder={t('app.newApp.appNamePlaceholder') || ''}
          />
        </div>
        <div className={`mt-6 font-medium ${s.settingTitle} text-gray-900`}>{t(`${prefixSettings}.type`)}</div>
        <div className='flex mt-2'>
          <SimpleSelect
            canClear={false}
            items={BOT_TYPE_OPTIONS}
            defaultValue={BotType.ChatBot}
            onSelect={(item) => { setInputInfo(origin => ({ ...origin, botType: item.value as BotType })) }}
            wrapperClassName="w-full"
          />
        </div>
        <div className='mt-[112px] flex justify-end'>
          <Button className='mr-2 flex-shrink-0 !text-sm' onClick={onClose}>{t('common.operation.cancel')}</Button>
          <Button variant='primary' className='flex-shrink-0 !text-sm' onClick={onClickSave} loading={saveLoading}>{t('common.operation.confirm')}</Button>
        </div>
      </Modal >
    </>

  )
}
export default React.memo(SettingsModal)
