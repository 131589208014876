'use client'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useGetState, useInfiniteScroll } from 'ahooks'
import { useContext } from 'use-context-selector'
import classNames from 'classnames'
import Button from '@/app/components/base/button'
import Dialog from '@/app/components/base/dialog'
import i18n from '@/i18n/i18next-config'
import { PortalSelect } from '@/app/components/base/select'
import { bindShopifyShopWithChatbot } from '@/service/integrations'
import { fetchAppList } from '@/service/apps'
import type { App } from '@/types/app'
import { ToastContext } from '@/app/components/base/toast'
import Loading from '@/app/components/base/loading'

type BindShopifyDialogProps = {
  show: boolean
  onSuccess?: () => void
  onClose?: () => void
  onCreateCallback: () => void
  storeName: string
  canCreateNewApp: boolean
}

const BindShopifyShopDialog = ({ show, onClose, onCreateCallback, storeName, canCreateNewApp }: BindShopifyDialogProps) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  const searchParams = useSearchParams()
  const router = useRouter()

  const { notify } = useContext(ToastContext)

  const selectRef = useRef<HTMLDivElement>(null)
  const [page, setPage, getPage] = useGetState(1)
  const [isNoMore, setIsNoMore] = useState(false)
  const [chatbotList, setChatbotList] = useState<App[]>([])

  const [selectedChatbot, setSelectedChatbot] = useState('')
  const [bindLoading, setBindLoading] = useState(false)
  const [isValidFail, setIsValidFail] = useState(false)

  useInfiniteScroll(
    async () => {
      if (!isNoMore) {
        const { data, has_more } = await fetchAppList({ url: '/apps', params: { page, not_bound_shopify: true } })
        setPage(getPage() + 1)
        setIsNoMore(!has_more)
        const newChatbotList = [...(chatbotList || []), ...data]
        setChatbotList(newChatbotList)
      }
      return { list: [] }
    },
    {
      target: selectRef.current,
      isNoMore: () => {
        return isNoMore
      },
      reloadDeps: [isNoMore],
    },
  )

  const handleBind = async () => {
    if (!selectedChatbot) {
      setIsValidFail(true)
      return
    }
    try {
      setBindLoading(true)
      await bindShopifyShopWithChatbot(`/apps/${selectedChatbot}/shopify_bind`, { bind_id: searchParams.get('shopify_bind_id') })
      router.replace(`/app/${selectedChatbot}/configuration`)
      notify({
        type: 'success',
        message: systemT('app.shopify.bindSuccessTip'),
      })
    }
    catch (err) {
      const error = await err.json()
      if (error.status === 403 && error.code === 'shopify_quota_exceeded') {
        notify({
          type: 'error',
          message: systemT('app.shopify.limit'),
        })
      }
    }
    finally {
      setBindLoading(false)
    }
  }

  return <>
    <Dialog
      show={show}
      className='!w-[520px] h-[400px]'
      title={systemT('app.shopify.bindShopTitle')}
      footer={
        <div className={classNames(isValidFail ? 'mt-[60px]' : 'mt-24')}>
          <Button variant='primary' onClick={handleBind}>{systemT('app.shopify.bindConfirm')}</Button>
        </div>
      }
    >
      <div>
        <h3 className='text-gray-900 text-sm font-medium mb-2'>{systemT('app.shopify.shopLabel')}</h3>
        <div className='flex items-center justify-between gap-3'>
          <input className='h-10 px-3 text-sm font-normal bg-gray-100 rounded-lg grow border border-gray-200 focus:outline-none focus:ring-primary-600 focus:border-primary-600' disabled value={storeName} />
        </div>
      </div>
      <div className='mt-4'>
        <h3 className='text-gray-900 text-sm font-medium mb-2'>{systemT('app.shopify.chatbotLabel')}</h3>
        <div className='w-full'>
          <PortalSelect
            ref={selectRef}
            value={selectedChatbot}
            items={chatbotList.filter((app: any) => !app.name.includes('#hide')).map(chatbot => ({
              value: chatbot.id,
              name: chatbot.name,
            })).concat([{
              value: 'create',
              name: systemT('app.createApp'),
            }])}
            onSelect={
              (selectedItem) => {
                setIsValidFail(false)
                if (selectedItem.value === 'create') {
                  if (!canCreateNewApp) {
                    notify({
                      type: 'warning',
                      message: systemT('workspace.confirm.application.title'),
                    })
                    return
                  }
                  onClose?.()
                  onCreateCallback()
                }
                else {
                  setSelectedChatbot(selectedItem.value as string)
                }
              }
            }
            popupClassName='w-[456px] !z-[102]'
            forceDestroyOption={false}
          />
          {isValidFail && (
            <div className='text-uni-text-11 text-xs font-normal mb-3 mt-2'>{systemT('app.shopify.bindShopTitle')}</div>
          )}
        </div>
      </div>
      {bindLoading && <Loading type='area' className='absolute z-10 bg-white/75 top-0 left-0 h-full'/>}
    </Dialog>
  </>
}

export default BindShopifyShopDialog
